import React, { Component } from 'react';
import { connect } from 'react-redux';
import GeneralSitePage from '../../components/GeneralSitePage';

class Navidad extends Component {
    render() {
        return <GeneralSitePage content={this.props.content} />;
    }
}

Navidad.defaultProps = {
    content: [
        {
            type: "image",
            value: '2024/navidad/diciembre.JPG',
            title: 'Besito de vacaciones',
            description: 'Cuando nos usaron de modelo en Xcaret'
        },
        {
            type: "text",
            value: `Hola monito, ¡Feliz Navidad!`
        },
        {
            type: "text",
            value: `Te amo mucho y me haces muy feliz. Gracias por permitirme pasar otra Navidad a tu lado. Me hace muy feliz poder estar con monito, pasar todo el día a su lado, abrazados en la camita y viendo películas locas.`
        },
        {
            type: "text",
            value: `Quiero que monito esté feliz en Navidad. Aunque monito haya sufrido una cirugía, quiero que esté feliz y llena de amor de esposo, con muchos cariñitos y cuidados para que se recupere y vuelva a ser un monito muy loco.`
        },
        {
            type: "text",
            value: `Te amo mucho y quiero ir a la camita con mono, y poder despertar tarde, abrazados y con gatitos encimosos a nuestro lado.`
        },
        {
            type: "text",
            value: `Esposa no va a encontrar un regalo en el árbol esta Navidad, pero espero que las ofrendas y su regalo navideño le hayan gustado y le sean útiles en sus entrenamientos diarios.`
        },
        {
            type: "text",
            value: `Te amo mucho, esposa. Eres un monito muy hermoso, aunque dijeras que eres una abominación. Eres mi vida y mi todo, y estoy muy feliz de poder pasar la Navidad a tu lado. Gracias por hacer todos los días muy bonitos, y llenos de amor y muchas locuras.`
        },
        {
            type: "header",
            value: `¡Feliz Navidad, esposa hermosa!`
        },
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Navidad);
